<template>
  <CabinetPage :preloader="preloader">
    <div class="mx-cabinet-page-header">
      <router-link :to="{name: 'faq.show', params: {id: $route.params.company_id}}" class="btn btn-outline-secondary">
        <ph-caret-left :size="22" />
      </router-link>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="mx-form-header">
          <h4 class="mb-3">{{ $t('faq.question.update') }}</h4>
          <a href="#" class="btn btn-danger" @click.prevent="deleteModal(true)">
            <ph-trash-simple :size="22" />
          </a>
        </div>
        <form class="mx-form">
          <div class="mx-modal-form">
            <div class="mb-3">
              <div class="mx-modal-form__element" v-for="element in form_elements">
                <label class="form-label">{{ $t(`faq.question.field.${element}`) }}</label>
                <input type="text" class="form-control" v-model="form[element]">
                <div v-if="errors[element]" class="invalid-feedback">
                  {{ $t(`question.errors.${getErrorWord(errors[element][0])}`) }}
                </div>
              </div>
              <div class="mx-modal-form__element">
                <label class="form-label">{{ $t(`faq.question.field.text`) }}</label>
                <textarea class="form-control" cols="30" rows="6" v-model="form.text"></textarea>
              </div>
            </div>
          </div>
          <div class="mx-form-buttons">
            <router-link :disabled="button_disabled" tag="button" :to="{name: 'faq.show', params: {id: $route.params.faq_id}}" class="btn btn-peepz-default">
              {{ $t('company.abort') }}
            </router-link>
            <button :disabled="button_disabled" @click="save" type="button" class="btn btn-peepz-success">{{ $t('company.save') }}</button>
          </div>
        </form>

      </div>
    </div>
    <b-modal ref="deleteModal" centered hide-header hide-footer no-close-on-backdrop no-close-on-esc>
      <div class="mx-modal-prompt">
        <h3 class="mx-modal-prompt__title">{{ $t('faq.question.prompt.title') }}</h3>
        <strong class="mx-modal-prompt__subtitle">{{ $t('faq.question.prompt.subtitle') }}</strong>
        <div class="mx-modal-prompt__buttons">
          <button type="button" :disabled="button_disabled" class="btn btn-peepz-danger" @click="ElementDelete">{{ $t('company.delete') }}</button>
          <button type="button" :disabled="button_disabled" class="btn btn-peepz-default" @click="deleteModal(false)">{{ $t('company.abort') }}</button>
        </div>
      </div>
    </b-modal>
  </CabinetPage>
</template>

<script>

import CabinetPage from "@/components/main/CabinetPage";

import {
  PhPlus,
  PhLockLaminated,
  PhCaretLeft,
  PhTrashSimple
} from "phosphor-vue";

export default {
  components: {CabinetPage, PhPlus, PhLockLaminated, PhCaretLeft, PhTrashSimple},
  data() {
    return {
      mobile: false,
      button_disabled: false,
      preloader: true,
      form_elements: [
        'title',
        // 'text',
        'notice',
      ],
      form: {
        id: null,
        company_id:  this.$route.params.company_id,
        faq_id:  this.$route.params.faq_id,
        title: null,
        text: null,
        notice: null,
      },
      errors: [],
    }
  },
  mounted() {
    this.mobile = window.innerWidth <= '768'
    this.getQuestion()

  },
  methods: {
    deleteModal(bool) {
      if (bool) {
        this.$refs['deleteModal'].show()
      } else {
        this.$refs['deleteModal'].hide()
      }
    },
    ElementDelete() {
      this.$store.dispatch('questionDelete', this.form.id).then(() => {
        this.$router.replace({
          name: 'faq.show', params: {company_id: this.form.faq_id}
        })
      })
    },
    getQuestion() {
      this.$store.dispatch('faqs', this.$route.params.company_id).then(() => {
        let faq = this.$store.getters.faqs.find(faq => faq.company_id === parseInt(this.$route.params.company_id))
        let question = faq.questions.find(q => q.id === parseInt(this.$route.params.question_id))

        this.form.id = question.id
        this.form.title = question.title
        this.form.text = question.text
        this.form.notice = question.notice

        this.preloader = false
      })
    },
    save() {
      this.button_disabled = true;
      this.$store.dispatch('questionUpdate', this.form).then(() => {
        this.$router.replace({
          name: 'faq.show', params: {company_id: this.form.company_id}
        })
      }).catch(error => {
        this.button_disabled = false;
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data;
            break;
        }
      })
    },
    getErrorWord(str) {
      return str.match(/\b(required|number)\b/g)[0]
    }
  }
}
</script>
